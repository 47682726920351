import Login from "../views/Login";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

export default function PublicRoutes() {
  return (
    <Routes>
      <Route exact path="/Login" element={<Login />} />
    </Routes>
  );
}
