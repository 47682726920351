export function IsLoggedIn() {
  if (
    localStorage.getItem("token") == null ||
    localStorage.getItem("token") == ""
  ) {
    return false;
  }
  return true;
}

export function Logout() {
  localStorage.setItem("token", "");
  localStorage.setItem("refresh", "");
  window.location.href = "/login";
}

export function Login(token, refresh) {
  localStorage.setItem("token", token);
  localStorage.setItem("refresh", refresh);
}
