import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
} from "@mui/material";

export default function AddUsers(props) {
  return (
    <Dialog open={props.show}>
      <DialogContent sx={{ backgroundColor: "#D8D9DA" }}>
        <TextField
          autoFocus
          margin="dense"
          label="Name"
          fullWidth
          variant="standard"
          onChange={(e) => props.onNameValueChange(e.target.value)}
        />
      </DialogContent>
      <DialogActions sx={{ backgroundColor: "#D8D9DA" }}>
        <Button variant="contained" onClick={props.close}>
          Cancel
        </Button>
        <Button variant="contained" color="success" onClick={props.add}>
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
}
