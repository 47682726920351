import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";

const initialStateValue = [];
const sample = { id: "", text: "", type: "", show:false };

export const messagesSlice = createSlice({
  name: "messages",
  initialState: { value: initialStateValue },
  reducers: {
    pushMessage: (state, action) => {
      action.payload.id = uuidv4();
      state.value.push(action.payload);
    },
    removeMessage: (state, action) => {
      state.value.filter((x) => x.id == action.payload).show = false;
      state.value = state.value.filter((x) => x.id != action.payload);
    },
  },
});

export const { pushMessage, removeMessage } = messagesSlice.actions;

export default messagesSlice.reducer;
