import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";

import "../../App.css";
import { Box, Button, IconButton, TableFooter } from "@mui/material";
import { NavigateBefore, NavigateNext } from "@mui/icons-material";

export default function DataTable(props) {
  const [page, setPage] = React.useState(0);

  const handleChangePage = (event, newPage) => {
    console.log(props);
    if (newPage >= props.pagination.totalPages) {
      return;
    }
    props.handlePageChanged(page < newPage);
    setPage(newPage);
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow
              sx={{
                "& th": {
                  color: "black",
                  backgroundColor: "#D8D9DA",
                },
                "& .MuiTableCell-root": {
                  borderBottom: "1px solid #67635e",
                },
              }}
            >
              {props.columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth, fontSize:"120%" }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.rows.map((row) => {
              return (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={row.code}
                  sx={{
                    backgroundColor: "#D8D9DA",
                    "&.MuiTableRow-hover:hover": {
                      backgroundColor: "#D8D9DA",
                    },
                    "& .MuiTableCell-root": {
                      borderBottom: "1px solid #67635e",
                    },
                  }}
                >
                  {props.columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        sx={{ color: "black" }}
                      >
                        {column.format && typeof value === "number"
                          ? column.format(value)
                          : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        sx={{ backgroundColor: "#D8D9DA", color:"black" }}
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={props.pagination.totalItems}
        rowsPerPage={props.rowsPerPage}
        page={props.pagination.currentPage-1}
        onPageChange={handleChangePage}
        onRowsPerPageChange={(event) =>
          props.handleChangeRowsPerPage(+event.target.value)
        }
      />
    </Paper>
  );
}
