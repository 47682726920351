
import './App.css';
import Messages from "./components/layout/Messages";
import MenuAppBar from "./components/layout/MenuAppBar";
import { BrowserRouter } from "react-router-dom";
import PrivateRoutes from "./routes/PrivateRoutes";
import PublicRoutes from "./routes/PublicRoutes";
import { StyledEngineProvider } from "@mui/material/styles";
//https://colorhunt.co/palette/27282961677ad8d9dafff6e0
//#272829 main
//#61677A secondary
//#D8D9DA third
//#FFF6E0 fourth

function App() {
  return (
    <div>
      <StyledEngineProvider injectFirst>
        <Messages />
        <BrowserRouter>
          <PublicRoutes />
          <MenuAppBar>
            <PrivateRoutes />
          </MenuAppBar>
        </BrowserRouter>
      </StyledEngineProvider>
    </div>
  );
}

export default App;
