import { Button, Snackbar, Stack } from "@mui/material";
import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import MuiAlert from "@mui/material/Alert";
import { useDispatch } from "react-redux";
import { pushMessage, removeMessage } from "../../features/messages";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Messages() {
  const messages = useSelector((state) => state.messages.value);

  return (
    <>
      {messages.map((m, i) => {
        return <MessageElement message={m} marginBottom={(i*55)+"px"}/>;
      })}
    </>
  );
}

function MessageElement(props) {
  const dispatch = useDispatch();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(removeMessage(props.message.id));
  };

  return (
    <Stack spacing={2} sx={{ width: "100%" }}>
      <Snackbar
        open={props.message.show}
        autoHideDuration={5000}
        onClose={handleClose}
        anchorOrigin={{vertical:"bottom", horizontal:"right"}}
        sx={{marginBottom:props.marginBottom}}
      >
        <Alert
          severity={props.message.type}
          sx={{ width: "100%" }}
          onClose={handleClose}
        >
          {props.message.text}
        </Alert>
      </Snackbar>
    </Stack>
  );
}
