import { createSlice } from "@reduxjs/toolkit";

const initialStateValue = <></>;

export const titleSlice = createSlice({
  name: "title",
  initialState: { value: initialStateValue },
  reducers: {
    setTitle: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setTitle } = titleSlice.actions;

export default titleSlice.reducer;
