import DashboardIcon from "@mui/icons-material/Dashboard";
import SettingsIcon from "@mui/icons-material/Settings";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";


const dashboard = [
  {
    name: "Dashboard",
    path: "/",
    icon: <DashboardIcon />,
  },
];

const settings = [
  {
    name: "Settings",
    path: "/Settings",
    icon: <SettingsIcon />,
  },
];

export const HomeMenuBehaviour = [
  dashboard,
  settings,
];

export const SettingsBehaviour = [
  dashboard,
  [
    {
      name: "Settings",
      path: "/Settings",
      icon: <SettingsIcon />,
    },
    {
      name: "Users",
      path: "/Settings/Users",
      icon: <ManageAccountsIcon />,
    },
  ],
];
