import React, { useEffect, useState } from "react";
import { pushMessage } from "../../../features/messages";
import { useDispatch } from "react-redux";
import DataTable from "../../../components/data/DataTable";
import PasswordIcon from "@mui/icons-material/Password";
import {
  ApiDelete,
  ApiGet,
  ApiPost,
  FormatData,
  FormatError,
} from "../../../services/ApiService";
import DeleteDialog from "../../../components/data/DeleteDialog";
import { Button, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddUsers from "./AddUsers";
import SetPassword from "./SetPassword";
import { setTitle } from "../../../features/title";
import { CreateLinkTitle } from "../../../utils/TitleUtils";

const columns = [
  { id: "name", label: "Name", align: "center", minWidth: 170 },
  { id: "password", label: "Set Password", align: "center", minWidth: 100 },
  {
    id: "delete",
    label: "Delete",
    minWidth: 170,
    align: "center",
  },
];

export default function Users() {
  const [rows, setRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pagination, setPagination] = useState({
    nextToken: null,
    previousToken: null,
    currentPage: 1,
    totalPages: 1,
    totalItems: 0,
  });
  const [addDialog, setAddDialog] = useState({
    show: false,
    name: "",
  });
  const [deleteDialog, setDeleteDialog] = useState({
    show: false,
    name: "",
  });
  const [passwordDialog, setPasswordDialog] = useState({
    show: false,
    name: "",
    password: "",
  });

  const dispatch = useDispatch();
  dispatch(
    setTitle(CreateLinkTitle("/Settings", "Settings", "Users"))
  );

  const createRow = (user) => {
    var deleteButton = (
      <IconButton onClick={() => onDeleteButtonClicked(user)}>
        <DeleteIcon color="error" />
      </IconButton>
    );
    var setPassowrdButton = (
      <IconButton onClick={() => onSetPasswordButtonClicked(user)}>
        <PasswordIcon color="primary" />
      </IconButton>
    );

    return {
      name: user.name,
      password: setPassowrdButton,
      delete: deleteButton,
    };
  };

  const onSetPasswordButtonClicked = (user) => {
    setPasswordDialog({
      show: true,
      name: user.name,
      password: null,
    });
  };

  const onDeleteButtonClicked = (user) => {
    setDeleteDialog({
      show: true,
      name: user.name,
    });
  };

  const getData = async (token, limit) => {
    var result = await ApiGet(
      dispatch,
      "Users?limit=" + limit + "&token=" + (token == null ? "" : token)
    );

    if (result != null) {
      setRows(result.data.items.map((x) => createRow(x)));
      setPagination({
        nextToken: result.data.nextToken,
        previousToken: result.data.previousToken,
        currentPage: result.data.currentPage,
        totalPages: result.data.totalPages,
        totalItems: result.data.totalItemCount,
      });
    }
  };

  useEffect(() => {
    getData(null, rowsPerPage);
  }, []);

  const handlePageChanged = async (v) => {
    if (v && pagination.nextToken != null) {
      await getData(pagination.nextToken, rowsPerPage);
    } else if (!v && pagination.previousToken != null) {
      await getData(pagination.previousToken, rowsPerPage);
    }
  };

  const handleChangeRowsPerPage = async (v) => {
    setRowsPerPage(v);
    await getData(null, v);
  };

  const addClicked = async () => {
    var result = await ApiPost(dispatch, "Users", {
      name: addDialog.name,
    });
    if (result) {
      setAddDialog((prv) => ({ ...prv, show: false }));
      getData(null, rowsPerPage);
    }
  };

  const onSetPasswordClicked = async () => {
    var result = await ApiPost(
      dispatch,
      "Users/" + passwordDialog.name + "/SetPassword",
      {
        password: passwordDialog.password,
      }
    );
    if (result) {
      setPasswordDialog((prv) => ({ ...prv, show: false }));
    }
  };

  const deleteConfirmed = async () => {
    var result = await ApiDelete(dispatch, "Users/" + deleteDialog.name);
    if (result) {
      setDeleteDialog((prv) => ({ ...prv, show: false }));
      getData(null, rowsPerPage);
    }
  };

  return (
    <>
      <Button
        variant="contained"
        sx={{ margin: "5px" }}
        onClick={() =>
          setAddDialog({ show: true, name: "", phone: null, address: null })
        }
      >
        Add
      </Button>
      <AddUsers
        show={addDialog.show}
        onNameValueChange={(value) =>
          setAddDialog((prv) => ({ ...prv, name: value }))
        }
        close={() => setAddDialog((prv) => ({ ...prv, show: false }))}
        add={addClicked}
      />
      <DeleteDialog
        name={deleteDialog.name}
        show={deleteDialog.show}
        onDeleteClicked={deleteConfirmed}
        close={() => setDeleteDialog((prv) => ({ ...prv, show: false }))}
      />
      <SetPassword
        show={passwordDialog.show}
        passwordSet={onSetPasswordClicked}
        onPasswordChanged={(value) => {
          setPasswordDialog((prv) => ({ ...prv, password: value }));
        }}
        user={passwordDialog.name}
        close={() => setPasswordDialog((prv) => ({ ...prv, show: false }))}
      />
      <DataTable
        columns={columns}
        rows={rows}
        pagination={pagination}
        rowsPerPage={rowsPerPage}
        handleChangeRowsPerPage={(v) => handleChangeRowsPerPage(v)}
        handlePageChanged={(v) => handlePageChanged(v)}
      />
    </>
  );
}
