import axios from "axios";
import { pushMessage } from "../features/messages";
import { Logout } from "../utils/AuthUtils";

const baseUrl = process.env.REACT_APP_API_URL;

export async function ApiGet(dispatch, path, refresh = false) {
  var url = baseUrl + path;
  return await axios
    .get(url, getConfig())
    .then(({ data }) => {
      return FormatData(data);
    })
    .catch(async (error) => {
      if (await handleError(error, dispatch, refresh)) {
        return await ApiGet(dispatch, path, true);
      }
      if (dispatch != null) {
        var formattedError = FormatError(error);
        dispatch(pushMessage(formattedError.message));
      }
      return null;
    });
}

export async function ApiDelete(dispatch, path, refresh = false) {
  var url = baseUrl + path;
  return await axios
    .delete(url, getConfig())
    .then(({ data }) => {
      var formattedData = FormatData(data);
      if (dispatch != null) {
        dispatch(pushMessage(formattedData.message));
      }
      return formattedData;
    })
    .catch(async (error) => {
      if (await handleError(error, dispatch, refresh)) {
        return await ApiDelete(dispatch, path, true);
      }
      if (dispatch != null) {
        var formattedError = FormatError(error);
        dispatch(pushMessage(formattedError.message));
      }
      return false;
    });
}

export async function ApiPost(dispatch, path, data, refresh = false) {
  var url = baseUrl + path;
  return await axios
    .post(url, data, getConfig())
    .then(({ data }) => {
      var formattedData = FormatData(data);
      if (dispatch != null) {
        dispatch(pushMessage(formattedData.message));
      }
      return formattedData;
    })
    .catch(async (error) => {
      if (await handleError(error, dispatch, refresh)) {
        return await ApiPost(dispatch, path, data, true);
      }
      if (dispatch != null) {
        var formattedError = FormatError(error);
        dispatch(pushMessage(formattedError.message));
      }
      return false;
    });
}

export async function ApiPut(dispatch, path, data, refresh = false) {
  var url = baseUrl + path;
  return await axios
    .put(url, data, getConfig())
    .then(({ data }) => {
      var formattedData = FormatData(data);
      if (dispatch != null) {
        dispatch(pushMessage(formattedData.message));
      }
      return formattedData;
    })
    .catch(async (error) => {
      if (await handleError(error, dispatch, refresh)) {
        return await ApiPut(dispatch, path, data, true);
      }
      if (dispatch != null) {
        var formattedError = FormatError(error);
        dispatch(pushMessage(formattedError.message));
      }
      return false;
    });
}

export async function ApiLogin(dispatch, path, data) {
  var url = baseUrl + path;
  return await axios
    .post(url, data)
    .then(({ data }) => {
      var formattedData = FormatData(data);
      dispatch(
        pushMessage({
          text: "Login Successful!",
          type: "success",
          show: true,
        })
      );
      return formattedData;
    })
    .catch((error) => {
      var formattedError = FormatError(error);
      dispatch(pushMessage(formattedError.message));
      return false;
    });
}

async function handleError(error, dispatch, refresh) {
  if (error.response && error.response.status === 401) {
    if (refresh) {
      Logout();
    }
    await refreshToken(dispatch);
    return true;
  }
  return false;
}

async function refreshToken(dispatch) {
  var url = baseUrl + "Auth/Refresh";
  var result = await axios
    .post(url, {
      refreshToken: localStorage.getItem("refresh"),
    })
    .then(({ data }) => {
      var formattedData = FormatData(data);
      return formattedData;
    })
    .catch((error) => {
      return false;
    });

  if (result) {
    localStorage.setItem("token", result.data.accessToken);
    localStorage.setItem("refresh", result.data.refreshToken);
  } else {
    localStorage.setItem("token", "");
    localStorage.setItem("refresh", "");
    window.location.href = "/login";
  }
}

function FormatData(data) {
  var message = {
    text: data?.message ?? "Success!",
    type: "success",
    show: true,
  };
  return { data: data.data, message: message };
}

function FormatError(error) {
  var message = {
    text: error?.response?.data?.message ?? "Something went wrong.",
    type: error?.response?.status == 400 ? "warning" : "error",
    show: true,
  };
  return { message: message };
}

function getConfig() {
  var config = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  };
  return config;
}
