import {
  Avatar,
  Box,
  Button,
  Card,
  Container,
  CssBaseline,
  FormControlLabel,
  Grid,
  Link,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import LockIcon from "@mui/icons-material/Lock";
import { CheckBox } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { pushMessage } from "../features/messages";
import { ApiLogin } from "../services/ApiService";
import { Login as LoginUtil, Logout } from "../utils/AuthUtils";
import { useNavigate } from "react-router-dom";

export default function Login() {
  const [username, setUsername] = useState(null);
  const [password, setPassword] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = async () => {    
    var result = await ApiLogin(dispatch, "Auth/SignIn", {
      userName: username,
      password: password,
    }); 
    if (result) {
      LoginUtil(result.data.accessToken, result.data.refreshToken);
      navigate("/");
    }
  };
  localStorage.setItem("token", "");
  localStorage.setItem("refresh", "");
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ height: "100vh" }}
    >
      <Grid item>
        <Paper
          sx={{
            width: "400px",
            padding: "10px",
            backgroundColor: "#D8D9DA",
          }}
          alignItems="center"
          justifyContent="center"
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1 }}>
              <LockIcon />
            </Avatar>
            <Box noValidate sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                autoFocus
                onChange={(e) => {
                  setUsername(e.target.value);
                }}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                type="password"
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={handleSubmit}
              >
                Sign In
              </Button>
            </Box>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
}
