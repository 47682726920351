import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  Outlet,
} from "react-router-dom";
import Home from "../views/home/Home";
import Settings from "../views/settings/Settings";
import Users from "../views/settings/users/Users";
import { IsLoggedIn } from "../utils/AuthUtils";

export default function PrivateRoutes() {
  return (
    <>
      <Routes>
        <Route element={<RequireLogin />}>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/Settings" element={<Settings />} />
          <Route exact path="/Settings/Users" element={<Users />} />
        </Route>
      </Routes>
    </>
  );
}

const RequireLogin = () => {
  if (IsLoggedIn()) {
    return <Outlet />;
  }
  return <Navigate to="/login" />;
};
