import { Link, Typography } from "@mui/material";

export function CreateSimpleTitle(name) {
  return <Typography fontSize={20}>{name}</Typography>;
}

export function CreateLinkTitle(path, name, sub) {
  return (
    <>
      <Link fontSize={20} href={path} underline="hover" color={"white"}>
        {name}
      </Link>
      <Typography fontSize={20}>&nbsp;-&nbsp;{sub}</Typography>
    </>
  );
}
